import { showNotify } from "vant";
import axios from "axios";

export const myMixin = () => {
  const errorMsgBox = (notice) => {
    showNotify({ type: "primary", message: notice });
  };

  const successMsgBox = (notice) => {
    showNotify({ type: "primary", message: notice });
  };

  const apiAll = (data, api, config = {}) => {
    axios.defaults.headers.post["Content-Type"] = "application/json";

    return async () => {
      const { method = "POST", error, defaultValue = null } = config;
      let res = null;
      try {
        if (method.toUpperCase() === "GET") {
          res = await axios.get(api, {
            data: data,
          });
        } else {
          res = await axios.post(api, data);
        }
        res = res.data;
        return res;
      } catch (e) {
        this.$message({
          message: e.message,
          type: "error",
        });
        if (_.isFunction(error)) {
          await error(e);
        }
        return defaultValue;
      }
    };
  };
  const withLoading = async (asyncFunc) => {
    let res = null;
    try {
      res = await asyncFunc();
    } catch (e) {
      console.error("WithLoading Error: ", e);
    }
    return res;
  };

  return {
    errorMsgBox,
    successMsgBox,
    apiAll,
    withLoading,
  };
};
