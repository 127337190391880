import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";

const app = createApp(App);
//elelemtPlus以及全部icon
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

app.use(ElementPlus);
// import { createPinia } from "pinia";
// const pinia = createPinia();
// app.use(pinia);

import lodash from "lodash";
window._ = lodash;
import axios from "axios";
app.config.globalProperties.$axios = axios;
// axios.defaults.baseURL = "http://192.168.0.183:3000";
axios.defaults.baseURL =
  window.location.origin == "http://192.168.0.183:8080" ||
  window.location.origin == "http://localhost:8080"
    ? "http://154.12.24.81:39291/"
    : "http://154.12.24.81:39291/";
import {
  Progress,
  Button,
  Col,
  Row,
  TimePicker,
  DatePicker,
  Dialog,
  Tabbar,
  TabbarItem,
  Search,
  Swipe,
  SwipeItem,
  Field,
  Collapse,
  CollapseItem,
  Rate,
  Icon,
  Empty,
  Badge,
  Tab,
  Tabs,
  Loading,
  Space,
} from "vant";

// 2. 引入组件样式
import "vant/lib/index.css";

// 3. 注册你需要的组件
app.use(Button);
app.use(Col);
app.use(Row);
app.use(Dialog);
app.use(TimePicker);
app.use(DatePicker);
app.use(Progress);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Search);
app.use(Swipe);
app.use(SwipeItem);
app.use(Field);
app.use(Collapse);
app.use(CollapseItem);
app.use(Rate);
app.use(Icon);
app.use(Empty);
app.use(Badge);
app.use(Tab);
app.use(Tabs);
app.use(Space);
//组件复用
app.mixin({
  setup() {},
  methods: {},
});
app.use(router).mount("#app");
