import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import index from "../components/index.vue";
const router = createRouter({
  routes: [
    {
      path: "/",
      name: "index",
      component: index,
      meta: {
        title: "主页",
        requireAuth: true,
      },
    },
  ],
  history: createWebHashHistory(),
});
export default router;
